// gatsby-browser.js

import Prism from "prismjs" // Prismを先にインポート

// Prismのテーマをインポート
import "prismjs/themes/prism-okaidia.css"

// プラグインのスタイルシートをインポート
import "prismjs/plugins/toolbar/prism-toolbar.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

// 言語コンポーネントをインポート
import "prismjs/components/prism-csharp"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-python"
import "prismjs/components/prism-bash"
import "prismjs/components/prism-css"
// 必要に応じて他の言語もインポート

// プラグインをインポート
import "prismjs/plugins/toolbar/prism-toolbar"
import "prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard"
import "prismjs/plugins/line-numbers/prism-line-numbers"

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    window.Prism = Prism
  }
}
